// faqs.js

const faqs = [
    {
      question: "What is ReviewStream ?",
      answer: "ReviewStream.com is an international, privately held publisher focused on consumer-related content. Founded in 2005, it emerged at a time when most online content was dominated by marketing-driven material designed to sell products. ReviewStream’s mission—then and now—is to amplify the voices of real consumers. Over the years, by leveraging unique algorithms and extensive data, it has developed an independent platform for authentic consumer opinions. Significant effort has been dedicated to safeguarding the ReviewStream digital ecosystem from individuals who do not uphold its core principle: honesty. As a result, we’re proud to offer reliable information shared by real consumers, for the benefit of other consumers."
    },
    {
      question: "How do I register ?",
      answer: "We are not in the business of selling personal data, so we don’t require registration or collect your information that way. You’ll gain access to the back office upon submitting your first piece of content. Once there, you can view details related to your activity with us, including content processing status and your total earnings."
    },
    {
      question: "What kind of content can I write ?",
      answer: "We are interested in honest and helpful content on any topic you’re familiar with."
    },
    {
      question: "Do I need to install any additional software ?",
      answer: "No, you don’t need to install any additional software."
    },
    {
      question: "I'm not sure about my grammar.",
      answer: "Our goal is to share helpful information. If you possess unique insights and can communicate them effectively, your grammar is good enough to reach a global audience."
    },
    {
      question: "How much will I earn per content sold ?",
      answer: "The regular rate for content sold may vary and is always available for review on the <a style='text-decoration:underline;color:#ffffff;' href='https://www.reviewstream.com/writereview.php'>content submission page</a> at any time"
    },
    {
      question: "How can I see the total ?",
      answer: "You can view your current total and the processing status of your submissions on the dashboard, all in one convenient place."
    },
    {
      question: "What does the bulk rate mean ?",
      answer: "The 'bulk rate' applies to content that doesn’t fully meet our standard criteria. While we may still purchase such content, it will be at a reduced bulk rate rather than the regular rate.<br><br>On the <a style='text-decoration:underline;color:#ffffff;' href='https://www.reviewstream.com/writereview.php'>content submission page</a>, after writing your content, you’ll need to decide whether you’re willing to accept the bulk rate. If so, check the 'bulk price' box; if not, leave it unchecked.<br><br>Here’s how the payment evaluation works:<br>- If your content meets all our criteria, you’ll be paid the regular rate.<br>- If it doesn’t meet some criteria but the bulk rate box is checked, you’ll be paid the bulk rate.<br>- If it doesn’t meet some criteria and the bulk rate box isn’t checked, your content will be rejected (though you can resubmit it with the bulk rate box checked).<br>- If it fails to meet all criteria, your content will be rejected regardless of whether the bulk rate box is checked."
    },
    {
      question: "How much is the bulk rate ?",
      answer: "The bulk rate is calculated by dividing the regular rate by 5. For example, if the regular rate is $15.00 USD, the bulk rate would be $3.00 USD."
    },
    {
      question: "Can I publish same content on my own blog/website ?",
      answer: "No, publishing the same content on your own blog or website is strictly prohibited. Doing so may result in the termination of our cooperation."
    },
    {
      question: "How do I get paid ?",
      answer: "You will be paid via PayPal using the email address you provide when submitting your content. Payments are processed once your earnings reach a minimum threshold.<br><br>To determine the minimum amount required for payment, multiply the regular rate of the first content you sell in a new payment cycle by 10. For example:<br><br>- If your first content in the cycle is sold at a regular rate of $10.00 USD (or a bulk rate of $2.00 USD), the minimum payment threshold will be $100.00 USD.<br><br>- If your first content is sold at a regular rate of $16.00 USD, the minimum payment threshold will be $160.00 USD.<br><br>The threshold remains the same regardless of whether your content is paid at the regular or bulk rate."
    },
    {
      question: "Do you have a referral program ?",
      answer: "Yes, we offer a referral program where you can invite other content producers and earn a percentage of the regular rate for every piece of content they sell. This referral fee applies regardless of whether their content is paid at the regular or bulk rate. If you don’t sell content yourself but only refer others, your minimum payment threshold will match that of the first person you refer in a new payment cycle. You can obtain your unique referral link <a style='text-decoration:underline;color:#ffffff;' href='https://www.reviewstream.com/referral_link.php'>here</a>. The referral fees you earn will be added to your total immediately upon each publication of their content"

    },
    {
      question: "How long does it take to process the content ?",
      answer: "Typically, content processing takes up to 48 hours, though this may vary. Please note that we do not operate during weekends or public holidays."
    },
    {
      question: "How can I delete my account ?",
      answer: "You cannot delete an account because you don’t have one in the traditional sense. ReviewStream.com is not a social network but a publisher that purchases copyrights. The back office, accessible via your dashboard, tracks your activity as a seller and cannot be deleted for record-keeping purposes."
    },

  ];
  
  export default faqs;

import React, { useState, useEffect } from 'react';
import './css/InfiniteScrollComponent.css';

const InfiniteScrollComponent = ({ onItemsLoaded }) => { // Add prop
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const getFetchSize = () => {
    const width = window.innerWidth;
    if (width < 480) return 12;
    if (width < 768) return 16;
    return 24;
  };

  const fetchItems = async (reset = false) => {
    if (isLoading || (!reset && !hasMore)) return;
    setIsLoading(true);

    const limit = getFetchSize();
    const url = reset 
      ? `/homepage/api/fetch_items.php?limit=${limit}&reset=1` 
      : `/homepage/api/fetch_items.php?limit=${limit}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.error) {
        console.error('Fetch error:', data.error);
        setHasMore(false);
        return;
      }

      if (data.items.length === 0 || !data.has_more) {
        setHasMore(false);
      }

      setItems((prevItems) => {
        const newItems = reset ? data.items : prevItems.concat(data.items);
        // Notify parent when 48 items are loaded
        if (newItems.length >= 48) {
          onItemsLoaded(true);
        }
        return [...newItems];
      });
    } catch (error) {
      setHasMore(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchItems(true); // Reset on initial load
  }, []);

  const handleScroll = () => {
    const scrollPosition = window.innerHeight + document.documentElement.scrollTop;
    const triggerPoint = document.documentElement.offsetHeight - 2000;
    const bottomPosition = document.documentElement.offsetHeight;
    const scrollHeight = document.documentElement.scrollHeight;

    if (scrollPosition >= triggerPoint && !isLoading && hasMore) {
      fetchItems();
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isLoading, hasMore]);

  return (
    <div className="scroll-container">
      <div className="items-grid">
        {items.map((item) => (
          <div key={item.id} className="item-block">
            <a href={`./reviews/?p=${item.id}`} className="item-link">
              <div className="item-header">
                <div className="star-rating">
                  {[1, 2, 3, 4, 5].map((star) => {
                    const fillPercentage = Math.min(Math.max((item.star_fill - (star - 1) * 20), 0), 20) / 20;
                    const fillColor = fillPercentage > 0 ? '#98FF98' : 'none';
                    const strokeColor = fillPercentage > 0 ? '#98FF98' : '#A9A9A9';
                    return (
                      <svg
                        key={star}
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="star"
                      >
                        <path
                          d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21L12 17.77L5.82 21L7 14.14L2 9.27L8.91 8.26L12 2Z"
                          fill={fillColor}
                          stroke={strokeColor}
                          strokeWidth="1.5"
                        />
                      </svg>
                    );
                  })}
                </div>
                {item.comments > 0 && (
                  <span className="comments-bubble">{item.comments}</span>
                )}
              </div>
              <div className="item-image">
                <img
                  src={`/images_items/${item.item_pic}`}
                  alt="Item"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
              </div>
            </a>
          </div>
        ))}
      </div>
      {isLoading && <div className="loading">Loading more items...</div>}
      {!hasMore && items.length > 0 && (
        <div className="no-more">No more items to load</div>
      )}
    </div>
  );
};

export default InfiniteScrollComponent;
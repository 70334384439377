
import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import xLogo from './assets/bg.jpg';
import '@fontsource/comfortaa';
import '@fontsource/raleway';
import faqs from './faqs';
import InfiniteScrollComponent from './components/InfiniteScrollComponent';

function App() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isMediumScreen, setIsMediumScreen] = useState(window.innerWidth <= 1366);
  const [expandedFAQ, setExpandedFAQ] = useState(null);
  const [isFooterFixed, setIsFooterFixed] = useState(false);
  const [isFooterVisible, setIsFooterVisible] = useState(false);
  const [hasReachedScrollSection, setHasReachedScrollSection] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0); // Track scroll direction
  const scrollSectionRef = useRef(null);
  const faqSectionRef = useRef(null); // Ref for FAQ section

  const toggleFAQ = (index) => {
    setExpandedFAQ(expandedFAQ === index ? null : index);
  };

  useEffect(() => {
    if (window.location.hash === '#faq' && faqSectionRef.current) {
      faqSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    else{
      window.scrollTo(0, 0);
    }
  }, []);

  

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsMediumScreen(window.innerWidth <= 1366);
    };
    window.addEventListener('resize', handleResize);
    document.body.style.margin = '0';
    document.body.style.overflowX = 'hidden';
    return () => {
      window.removeEventListener('resize', handleResize);
      document.body.style.margin = '';
      document.body.style.overflowX = '';
    };
  }, []);

  // Handle footer visibility when 48 items are loaded
  const handleItemsLoaded = (shouldFix) => {
    if (shouldFix) {
      setIsFooterFixed(true);
      setIsFooterVisible(true); // Show footer when 48 items are loaded
    }
  };

  // Track scroll direction
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Use IntersectionObserver to detect when scroll section's top reaches viewport top
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          setHasReachedScrollSection(true);
          setIsFooterVisible(true); // Show footer when scroll section top hits viewport top
        } else if (!entry.isIntersecting && entry.boundingClientRect.top > 0) {
          // Hide footer when scrolling up past the scroll section
          setIsFooterVisible(false);
        }
      },
      {
        root: null,
        threshold: 0,
        rootMargin: '0px 0px 0px 0px',
      }
    );

    if (scrollSectionRef.current) {
      observer.observe(scrollSectionRef.current);
    }

    return () => {
      if (scrollSectionRef.current) {
        observer.unobserve(scrollSectionRef.current);
      }
    };
  }, []);

  // Use IntersectionObserver to detect when FAQ section's bottom reaches viewport top
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        const currentScrollY = window.scrollY;
        const scrollingUp = currentScrollY < lastScrollY;

        // Check if the bottom of the FAQ section is at or above the viewport top
        if (scrollingUp && entry.boundingClientRect.bottom <= 200) {
          setIsFooterVisible(false);
        }
      },
      {
        root: null,
        threshold: 0,
        rootMargin: '0px 0px 0px 0px', // Trigger based on boundingClientRect
      }
    );

    if (faqSectionRef.current) {
      observer.observe(faqSectionRef.current);
    }

    return () => {
      if (faqSectionRef.current) {
        observer.unobserve(faqSectionRef.current);
      }
    };
  }, [lastScrollY]);

  // Ensure footer visibility persists if 48 items are loaded and user scrolls down again
  useEffect(() => {
    const handleScroll = () => {
      if (scrollSectionRef.current && isFooterFixed) {
        const rect = scrollSectionRef.current.getBoundingClientRect();
        if (rect.top <= 0 && hasReachedScrollSection) {
          setIsFooterVisible(true);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isFooterFixed, hasReachedScrollSection]);

  const colors = {
    primaryBlue: '#3498db',
    darkGrey: '#34495e',
    mediumGrey: '#95a5a6',
    lightGrey: '#ecf0f1',
    white: '#ffffff',
    almostBlack: '#1a1a1a',
    red: '#ff0000',
  };

  const styles = {
    app: {
      fontFamily: 'Raleway, Arial, sans-serif',
      color: colors.darkGrey,
      margin: 0,
      padding: 0,
      overflowX: 'hidden',
      minHeight: '100vh',
      width: '100%',
    },
    header: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      padding: isMobile ? '0.5rem' : '1rem',
      zIndex: 1000,
      width: '100%',
      boxSizing: 'border-box',
    },
    nav: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      maxWidth: '100%',
      padding: isMobile ? '0 10px' : '0',
    },
    logo: {
      color: '#3cd49d',
      fontSize: isMobile ? '1.8rem' : '2.3rem',
      fontWeight: '600',
      fontFamily: 'Comfortaa, Arial, sans-serif',
      letterSpacing: '0.5px',
      textShadow: '1px 1px 2px rgba(0,0,0,0.1)',
    },
    menuIcon: {
      color: colors.white,
      fontSize: '1.5rem',
      background: 'none',
      border: 'none',
      cursor: 'pointer',
    },
    menuItems: {
      display: 'flex',
      gap: '1rem',
      alignItems: 'center',
      justifyContent: 'center',
    },
    mobileMenu: {
      position: 'absolute',
      top: '60px',
      left: 0,
      right: 0,
      backgroundColor: '#151515',
      padding: '1rem',
      zIndex: 999,
      width: '100%',
      boxSizing: 'border-box',
    },
    mobileMenuItem: {
      color: 'rgb(255, 225, 77)',
      textDecoration: 'none',
      display: 'block',
      padding: '0.5rem 0',
      fontSize: '1rem',
    },
    link: {
      color: 'rgb(255, 225, 77)',
      textDecoration: 'none',
      fontSize: isMobile ? '0.9rem' : '1rem',
    },
    hero: {
      width: '100%',
      minHeight: '100vh',
      backgroundColor: '#151515',
      backgroundImage: `url(${xLogo})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      position: 'relative',
      zIndex: 100,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#3cd49d',
      textAlign: 'center',
      margin: 0,
      padding: isMobile ? '2rem 1rem' : '4rem 2rem',
      boxSizing: 'border-box',
    },
    heroOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(21, 21, 21, 0.75)',
    },
    mainButtonWrapper: {
      width: '100%',
      zIndex: 1,
      position: 'relative',
      top: isMobile ? '20px' : '80px',
      padding: isMobile ? '0 10px' : '0',
      boxSizing: 'border-box',
    },
    mainButton: {
      width: isMobile ? '100%' : isMediumScreen ? '300px' : '350px',
      maxWidth: '100%',
      height: isMobile ? '50px' : '80px',
      borderRadius: '1.25em',
      backgroundColor: 'rgb(255, 225, 77)',
      color: 'rgb(0, 0, 0)',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: isMobile ? '1.2rem' : isMediumScreen ? '1.6rem' : '1.8rem',
      fontWeight: '600',
      marginLeft: 'auto',
      marginRight: 'auto',
      textDecoration: 'none',
      boxSizing: 'border-box',
    },
    loginButton: {
      backgroundColor: 'rgb(255, 225, 77)',
      color: 'rgb(26,26,26)',
      padding: isMobile ? '6px 12px' : '8px 16px',
      borderRadius: '5px',
      fontWeight: 'bold',
      textDecoration: 'none',
      transition: 'background-color 0.3s ease, color 0.3s ease',
      fontSize: isMobile ? '0.9rem' : '1rem',
    },
    main: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 0,
      color: '#ffffff',
      width: '100%',
      overflowX: 'hidden',
      boxSizing: 'border-box',
    },
    sectionTestimonials: {
      width: '100%',
      minHeight: 'auto',
      backgroundColor: 'rgb(39, 39, 43)',
      position: 'relative',
      zIndex: 100,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#ffffff',
      textAlign: 'center',
      overflowX: 'hidden',
      padding: isMobile ? '2rem 0' : '4rem 0',
      boxSizing: 'border-box',
    },
    sectionPyramid: {
      width: '100%',
      backgroundColor: 'rgb(255, 225, 77)',
      position: 'relative',
      zIndex: 100,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      overflowX: 'hidden',
      boxSizing: 'border-box',
    },
    pyramidContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: isMobile ? '1rem' : '2rem',
      width: isMobile ? 'calc(100% - 10px)' : 'calc(100% - 20px)',
      maxWidth: isMobile ? '100%' : '1200px',
      margin: isMobile ? '1rem 5px' : '4rem 10px',
      backgroundColor: '#27272B',
      borderRadius: '2rem',
      color: '#D7D4CC',
      overflowX: 'hidden',
      boxSizing: 'border-box',
    },
    pyramidText: {
      textAlign: 'center',
      fontSize: isMobile ? '0.9rem' : '1.1rem',
      lineHeight: 1.6,
      maxWidth: '800px',
      padding: isMobile ? '0 0.5rem' : '0 1rem',
      color: '#D7D4CC',
    },
    sectionScroll: {
      width: '100%',
      minHeight: 'auto',
      backgroundColor: 'rgb(255, 225, 77)',
      position: 'relative',
      zIndex: 100,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#3cd49d',
      textAlign: 'center',
      overflowX: 'hidden',
      boxSizing: 'border-box',
    }, 
    scrollTitle: {
      fontSize: isMobile ? '1.5rem' : isMediumScreen ? '2.5rem' : '3rem',
      fontWeight: '700',
      color: 'rgb(39, 39, 43)',
      marginBottom: isMobile ? '1.5rem' : '3rem',
      fontFamily: 'Comfortaa, Arial, sans-serif',
      textAlign: 'center',
      wordBreak: 'break-word',
    },   
    sectionFaq: {
      width: '100%',
      minHeight: 'auto',
      backgroundColor: 'rgb(255, 225, 77)',
      position: 'relative',
      zIndex: 100,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#3cd49d',
      textAlign: 'center',
      overflowX: 'hidden',
      boxSizing: 'border-box',
    },
    faqContent: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      maxWidth: isMobile ? '100%' : '800px',
      margin: '0 auto',
      padding: isMobile ? '1rem' : '2rem',
      textAlign: 'left',
      width: '100%',
      boxSizing: 'border-box',
    },
    faqBlock: {
      backgroundColor: '#27272B',
      borderRadius: '1rem',
      padding: isMobile ? '0.8rem' : '1rem',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease',
      width: '100%',
      boxSizing: 'border-box',
    },
    faqQuestionContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    faqQuestion: {
      fontSize: isMobile ? '0.9rem' : '1.2rem',
      fontWeight: '600',
      color: '#D7D4CC',
      flex: 1,
      wordBreak: 'break-word',
    },
    faqArrow: {
      fontSize: isMobile ? '0.8rem' : '1rem',
      color: '#D7D4CC',
      marginLeft: '0.5rem',
      flexShrink: 0,
    },
    faqAnswer: {
      marginTop: '0.5rem',
      fontSize: isMobile ? '0.8rem' : '1rem',
      color: '#D7D4CC',
      lineHeight: '1.6',
      wordWrap: 'break-word',
      width: '100%',
    },
  };

  const responsiveStyles = {
    heroContent: {
      width: isMobile ? '100%' : isMediumScreen ? '90%' : '70%',
      padding: isMobile ? '0 10px' : '2rem',
      margin: '0 auto',
      position: 'relative',
      zIndex: 1,
      boxSizing: 'border-box',
    },
    heroContentLeft: {
      width: '100%',
    },
    mainTitle: {
      color: 'rgb(255, 225, 77)',
      fontWeight: '600',
      fontFamily: 'Comfortaa, Arial, sans-serif',
      letterSpacing: '0.5px',
      textShadow: '1px 1px 2px rgba(0,0,0,0.1)',
      fontSize: isMobile ? '1.5em' : isMediumScreen ? '2.5em' : '3em',
      marginTop: isMobile ? '0' : '40px',
      marginBottom: isMobile ? '15px' : '40px',
      wordBreak: 'break-word',
    },
    mainText: {
      color: '#ffffff',
      fontWeight: '400',
      lineHeight: '1.6',
      fontFamily: 'Raleway, Arial, sans-serif',
      letterSpacing: '0.5px',
      textShadow: '1px 1px 2px rgba(0,0,0,0.1)',
      textAlign: 'justify',
      fontSize: isMobile ? '0.85em' : isMediumScreen ? '1.1em' : '1.3em',
      padding: isMobile ? '0 5px' : '0 2rem',
      wordBreak: 'break-word',
    },
    pyramidContent: {
      display: 'flex',
      justifyContent: isMobile ? 'center' : 'space-between',
      maxWidth: isMobile ? '100%' : '800px',
      gap: isMobile ? '1rem' : '2rem',
      margin: '0 auto',
      flexDirection: isMobile ? 'column' : 'row',
      width: '100%',
      boxSizing: 'border-box',
      alignItems: isMobile ? 'center' : 'flex-start',
    },
    pyramidTitle: {
      fontSize: isMobile ? '1.5rem' : isMediumScreen ? '2.5rem' : '3rem',
      fontWeight: '700',
      color: '#FFE14D',
      marginBottom: isMobile ? '1.5rem' : '3rem',
      fontFamily: 'Comfortaa, Arial, sans-serif',
      textAlign: 'center',
      wordBreak: 'break-word',
    },
    levelContentOne: {
      marginLeft: isMobile ? '0.5rem' : '-12rem',
      borderBottom: isMobile ? 'none' : '6px solid rgba(215, 212, 204, 0.2)',
      width: isMobile ? '100%' : '350px',
      boxSizing: 'border-box',
      padding: '0 0 5px 0',
    },
    levelContentTwo: {
      marginLeft: isMobile ? '0.5rem' : '-8rem',
      borderBottom: isMobile ? 'none' : '6px solid rgba(215, 212, 204, 0.2)',
      width: isMobile ? '100%' : '350px',
      boxSizing: 'border-box',
      padding: '0 0 7px 0',
    },
    levelContentThree: {
      marginLeft: isMobile ? '0.5rem' : '-5rem',
      width: isMobile ? '100%' : '350px',
      boxSizing: 'border-box',
    },
    levelTextsOne: {
      position: 'relative',
      left: isMobile ? '0' : '0',
      display: isMobile ? 'flex' : 'block',
      alignItems: isMobile ? 'center' : 'unset',
      justifyContent: isMobile ? 'start' : 'unset',
      padding: isMobile ? '0 5px' : '0',
      width: isMobile ? '100%' : 'auto',
    },
    levelTextsTwo: {
      position: 'relative',
      left: isMobile ? '0' : '0',
      display: isMobile ? 'flex' : 'block',
      alignItems: isMobile ? 'center' : 'unset',
      justifyContent: isMobile ? 'start' : 'unset',
      padding: isMobile ? '0 5px' : '0',
      width: isMobile ? '100%' : 'auto',
    },
    levelTextsThree: {
      position: 'relative',
      left: isMobile ? '0' : '0',
      display: isMobile ? 'flex' : 'block',
      alignItems: isMobile ? 'center' : 'unset',
      justifyContent: isMobile ? 'start' : 'unset',
      padding: isMobile ? '0 5px' : '0',
      width: isMobile ? '100%' : 'auto',
    },
    pyramidTextNumber: {
      width: '30px',
      height: '30px',
      backgroundColor: 'rgb(70, 70, 74)',
      borderRadius: '50%',
      display: isMobile ? 'flex' : 'none',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: '0',
      marginRight: '0.5rem',
    },
  };

  const testimonialStyles = {
    testimonialContainer: {
      width: isMobile ? '100%' : '80%',
      maxWidth: '1200px',
      margin: '0 auto',
      padding: isMobile ? '1rem' : '4rem 2rem',
      boxSizing: 'border-box',
    },
    testimonialTitle: {
      fontSize: isMobile ? '1.5rem' : isMediumScreen ? '2.5rem' : '3rem',
      fontWeight: '700',
      color: '#FFE14D',
      marginBottom: isMobile ? '1.5rem' : '3rem',
      fontFamily: 'Comfortaa, Arial, sans-serif',
      textAlign: 'center',
      padding: isMobile ? '0 10px' : '0',
      wordBreak: 'break-word',
    },
    testimonialGrid: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: isMobile ? '1rem' : '2rem',
      marginBottom: '2rem',
      flexDirection: isMobile ? 'column' : 'row',
      width: '100%',
      padding: isMobile ? '0 10px' : '0',
      boxSizing: 'border-box',
    },
    testimonialCard: {
      backgroundColor: '#27272B',
      borderRadius: '1rem',
      padding: isMobile ? '1rem' : '2rem',
      flex: '1',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
      marginBottom: isMobile ? '1rem' : '0',
      width: '100%',
      boxSizing: 'border-box',
    },
    testimonialText: {
      color: '#D7D4CC',
      fontSize: isMobile ? '0.9rem' : '1.1rem',
      lineHeight: '1.6',
      marginBottom: '1.5rem',
      flex: '1',
      fontFamily: 'Raleway, Arial, sans-serif',
      textAlign: 'left',
      wordBreak: 'break-word',
    },
    testimonialAuthor: {
      color: 'rgb(215, 212, 204)',
      fontSize: isMobile ? '1rem' : '1.2rem',
      fontWeight: '400',
      marginTop: 'auto',
      fontFamily: 'Comfortaa, Arial, sans-serif',
    },
    testimonialQuote: {
      position: 'absolute',
      top: '1rem',
      right: '1rem',
      fontSize: isMobile ? '2.5rem' : '4rem',
      color: 'rgba(255, 225, 77, 0.1)',
      fontFamily: 'Georgia, serif',
      lineHeight: '1',
    },
    responsiveTestimonialButtons: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: isMobile ? '1rem' : '2rem',
      marginTop: '1.5rem',
      padding: isMobile ? '0 10px' : '0',
      width: '100%',
      boxSizing: 'border-box',
    },
    testimonialButton: {
      backgroundColor: 'rgb(255, 225, 77)',
      color: 'rgb(0, 0, 0)',
      padding: isMobile ? '0.5rem 1rem' : '0.8rem 2rem',
      borderRadius: '1.25em',
      fontWeight: '600',
      fontSize: isMobile ? '0.9rem' : isMediumScreen ? '1.1rem' : '1.2rem',
      cursor: 'pointer',
      border: 'none',
      fontFamily: 'Raleway, Arial, sans-serif',
      textDecoration: 'none',
      textAlign: 'center',
      width: isMobile ? '100%' : 'auto',
      boxSizing: 'border-box',
    },
    testimonialCashButton: {
      backgroundColor: 'rgb(255, 225, 77)',
      color: 'rgb(0, 0, 0)',
      padding: isMobile ? '0.5rem 1rem' : '0.8rem 2rem',
      borderRadius: '1.25em',
      fontWeight: '600',
      fontSize: isMobile ? '0.9rem' : isMediumScreen ? '1.1rem' : '1.2rem',
      cursor: 'pointer',
      border: 'none',
      fontFamily: 'Raleway, Arial, sans-serif',
      textDecoration: 'none',
      textAlign: 'center',
      width: isMobile ? '100%' : 'auto',
      boxSizing: 'border-box',
    },
  };

  const footerStyles = {
    footer: {
      width: '100%',
      backgroundColor: 'rgb(39, 39, 43)',
      color: 'rgb(215, 212, 204)',
      minHeight: '150px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-around',
      padding: isMobile ? '1rem' : '2rem 1rem',
      boxSizing: 'border-box',
      position: isFooterFixed ? 'fixed' : 'relative',
      bottom: isFooterFixed ? 0 : 'auto',
      left: 0,
      opacity: isFooterVisible ? 1 : 0,
      transition: 'opacity 0.5s ease-in', // Restore original smooth fade-in
      zIndex: 1000,
    },
    footerCashButton: {
      backgroundColor: 'rgb(255, 225, 77)',
      color: 'rgb(0, 0, 0)',
      padding: isMobile ? '0.5rem 1rem' : '0.8rem 2rem',
      borderRadius: '1.25em',
      fontWeight: '600',
      fontSize: isMobile ? '0.9rem' : isMediumScreen ? '1.1rem' : '1.2rem',
      cursor: 'pointer',
      border: 'none',
      fontFamily: 'Raleway, Arial, sans-serif',
      textDecoration: 'none',
      textAlign: 'center',
      margin: isMobile ? '0 0 1rem 0' : '0 0 1.5rem 0',
      width: isMobile ? '100%' : 'auto',
      maxWidth: '350px',
      boxSizing: 'border-box',
    },
    footerLinks: {
      display: 'flex',
      gap: isMobile ? '0.8rem' : '2rem',
      margin: '0 0 1rem 0',
      flexDirection: isMobile ? 'column' : 'row',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'center',
      padding: isMobile ? '0 10px' : '0',
      boxSizing: 'border-box',
    },
    footerLink: {
      color: '#ffffff',
      textDecoration: 'none',
      fontSize: isMobile ? '0.9rem' : '1rem',
    },
    footerCopyright: {
      color: '#ffffff',
      fontSize: isMobile ? '0.8rem' : '0.9rem',
      opacity: '0.8',
      textAlign: 'center',
      padding: isMobile ? '0 10px' : '0',
    },
  };

  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);
  const closeMobileMenu = () => setIsMobileMenuOpen(false);

  return (
    <Router> 
      <div style={styles.app}>
        <header style={styles.header}>
          <nav style={styles.nav}>
            <div style={styles.logo}></div>
            {isMobile ? (
              <button style={styles.menuIcon} onClick={toggleMobileMenu}>☰</button>
            ) : (
              <div style={styles.menuItems}>
                <a href="./dashboard" style={styles.loginButton}>
                  Dashboard
                </a>
                <a href="#testimonials" style={styles.link}>Testimonials</a>
                <a href="#faq" style={styles.link}>FAQ</a>
              </div>
            )}
          </nav>
        </header>

        {isMobile && isMobileMenuOpen && (
          <div style={styles.mobileMenu}>
            <a href="./dashboard" style={styles.mobileMenuItem} onClick={closeMobileMenu}>
              Dashboard
            </a>
            <a href="#testimonials" style={styles.mobileMenuItem} onClick={closeMobileMenu}>Testimonials</a>
            <a href="#faq" style={styles.mobileMenuItem} onClick={closeMobileMenu}>FAQ</a>
          </div>
        )}

        <main style={styles.main}>
          <section style={styles.hero}>
            <div style={styles.heroOverlay}></div>
            <div style={responsiveStyles.heroContent}>
              <div style={responsiveStyles.heroContentLeft}>
                <div style={responsiveStyles.mainTitle}>ReviewStream.com</div>
                <div style={responsiveStyles.mainText}>
                  Love to write? Turn your opinions into cash! Since 2005, we’ve been publishing real reviews—over 160,000 and counting—on everything from hit shows to unforgettable places. Got a take on the latest movie, a cozy café, or a product you can’t live without? Write it up, send it our way, and if we choose it, you’ll earn for your effort. For two decades, we’ve been paying writers like you to share what they know.
                </div>
              </div>
            </div>
            <div style={styles.mainButtonWrapper}>
              <a href="./writereview.php" style={styles.mainButton}>
                Cash In Your Take!
              </a>
            </div>
          </section>

          <section style={styles.sectionPyramid}>
            <div style={styles.pyramidContainer}>
              <h2 style={responsiveStyles.pyramidTitle}>Earn With Us</h2>
              <div style={responsiveStyles.pyramidContent}>
                <svg 
                  width={isMobile ? "280" : "400"} 
                  height={isMobile ? "280" : "400"} 
                  viewBox="0 0 400 400"
                  style={{ maxWidth: '100%', margin: '0 auto', display: 'block' }}
                >
                  <path d="M200,0 L263,108.8 L137,108.8 Z" fill="#46464A"/>
                  <text x="200" y="75" fill="#D7D4CC" fontSize="30" textAnchor="middle">1</text>
                  <path d="M131,118.8 L269,118.8 L331,227.6 L69,227.6 Z" fill="#46464A"/>
                  <text x="200" y="180" fill="#D7D4CC" fontSize="28" textAnchor="middle">2</text>
                  <path d="M63,237.6 L337,237.6 L400,346.4 L0,346.4 Z" fill="#46464A"/>
                  <text x="200" y="300" fill="#D7D4CC" fontSize="26" textAnchor="middle">3</text>
                </svg>

                <div style={{ position: 'relative', width: isMobile ? '100%' : 'auto' }}>
                  <div style={responsiveStyles.levelTextsOne}>
                    <div style={responsiveStyles.pyramidTextNumber}>1</div>
                    <div style={responsiveStyles.levelContentOne}>
                      <h3 style={{ fontSize: isMobile ? '1.2rem' : '1.5rem', color: '#D7D4CC', marginBottom: '0.8rem' }}>Expert Reviewer</h3>
                      <p style={{ color: '#D7D4CC', opacity: 0.8, fontSize: isMobile ? '0.9rem' : '1rem' }}>Top compensation</p>
                    </div>
                  </div>
                  <div style={responsiveStyles.levelTextsTwo}>
                    <div style={responsiveStyles.pyramidTextNumber}>2</div>
                    <div style={responsiveStyles.levelContentTwo}>
                      <h3 style={{ fontSize: isMobile ? '1.2rem' : '1.5rem', color: '#D7D4CC', marginBottom: '0.8rem' }}>Regular Contributor</h3>
                      <p style={{ color: '#D7D4CC', opacity: 0.8, fontSize: isMobile ? '0.9rem' : '1rem' }}>Consistent earnings</p>
                    </div>
                  </div>
                  <div style={responsiveStyles.levelTextsThree}>
                    <div style={responsiveStyles.pyramidTextNumber}>3</div>
                    <div style={responsiveStyles.levelContentThree}>
                      <h3 style={{ fontSize: isMobile ? '1.2rem' : '1.5rem', color: '#D7D4CC', marginBottom: '0.8rem' }}>New Reviewer</h3>
                      <p style={{ color: '#D7D4CC', opacity: 0.8, fontSize: isMobile ? '0.9rem' : '1rem' }}>Start earning today</p>
                    </div>
                  </div>
                </div>
              </div>
              <p style={styles.pyramidText}>
                Join our community of more than 10,000 authors who share their experiences and get compensated for their insights. 
                The more quality reviews you submit, the more you can earn.
              </p>
            </div>
          </section>

          <section style={styles.sectionTestimonials} id="testimonials">
            <div style={testimonialStyles.testimonialContainer}>
              <h2 style={testimonialStyles.testimonialTitle}>What Our Community Says</h2>
              <div style={testimonialStyles.testimonialGrid}>
                <div style={testimonialStyles.testimonialCard}>
                  <div style={testimonialStyles.testimonialQuote}>"</div>
                  <p style={testimonialStyles.testimonialText}>
                    This is a genuine opportunity. If you have no other way of making an income and you can write reviews of things that people want to read (and there are so many headings under which you can write), then you will get paid. You may not make a fortune, but you will be able to keep the wolf from the door. Also, it is a good way of measuring your own potential as a writer. If people are prepared to pay you for the words you write, then maybe you have something worthwhile to say!
                  </p>
                  <div style={testimonialStyles.testimonialAuthor}>— Anglecynn</div>
                </div>
                <div style={testimonialStyles.testimonialCard}>
                  <div style={testimonialStyles.testimonialQuote}>"</div>
                  <p style={testimonialStyles.testimonialText}>
                    My biggest problem with other review sites is that they don't seem to be people's actual opinions. Everywhere I look on ReviewStream.com they state that they only want reviews in your own opinion. ReviewStream is definitely the place to be and I plan on telling all of my friends. If you are already writing reviews for ReviewStream.com then you know exactly what I'm talking about, but if you haven't started writing reviews yet then you should get started.
                  </p>
                  <div style={testimonialStyles.testimonialAuthor}>— Mikaelyn B.</div>
                </div>
                <div style={testimonialStyles.testimonialCard}>
                  <div style={testimonialStyles.testimonialQuote}>"</div>
                  <p style={testimonialStyles.testimonialText}>
                    The most important thing I've gotten from review writing, is that my writing skills sharpen every day. As an aspiring writer I find this an asset with other writing projects. Writing reviews always makes me appreciate other peoples reviews, and points of view. I always read as many reviews as possible here at reviewstream.
                  </p>
                  <div style={testimonialStyles.testimonialAuthor}>— Melissa G.</div>
                </div>
              </div>
              <div style={testimonialStyles.responsiveTestimonialButtons}>
                <a href="./testimonials.php" style={testimonialStyles.testimonialButton}>
                  More Testimonials
                </a>
                <a href="./writereview.php" style={testimonialStyles.testimonialCashButton}>
                  Cash In Your Take!
                </a>
              </div>
            </div>
          </section>

          <section style={styles.sectionFaq} id="faq" ref={faqSectionRef}>
            <div style={styles.pyramidContainer}>
              <h2 style={responsiveStyles.pyramidTitle}>Frequently Asked Questions</h2>
              <div style={styles.faqContent}>
                {faqs.map((faq, index) => (
                  <div key={index} style={styles.faqBlock}>
                    <div style={styles.faqQuestionContainer} onClick={() => toggleFAQ(index)}>
                      <div style={styles.faqQuestion} dangerouslySetInnerHTML={{ __html: faq.question }} />
                      <span style={styles.faqArrow}>{expandedFAQ === index ? '▲' : '▼'}</span>
                    </div>
                    <div
                      style={{
                        ...styles.faqAnswer,
                        visibility: expandedFAQ === index ? 'visible' : 'hidden',
                        height: expandedFAQ === index ? 'auto' : '0',
                        overflow: 'hidden',
                        transition: 'height 0.3s ease, visibility 0.3s ease',
                      }}
                      dangerouslySetInnerHTML={{ __html: faq.answer }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </section>

          <section style={styles.sectionScroll} id="scroll" ref={scrollSectionRef}>
            <h2 style={styles.scrollTitle}>Review Snapshots Since 2005</h2>
            <InfiniteScrollComponent onItemsLoaded={handleItemsLoaded} />
          </section>

          <footer style={footerStyles.footer}>
            <a href="./writereview.php" style={footerStyles.footerCashButton}>
              Cash In Your Take!
            </a>
            <div style={footerStyles.footerLinks}>
              <a href="#testimonials" style={footerStyles.footerLink}>Testimonials</a>
              <a href="#faq" style={footerStyles.footerLink}>FAQ</a>
              <a href="./terms.php" style={footerStyles.footerLink}>Terms & conditions</a>
              <a href="./privacy.php" style={footerStyles.footerLink}>Privacy policy</a>
            </div>
            <div style={footerStyles.footerCopyright}>
              ReviewStream.com © {new Date().getFullYear()}
            </div>
          </footer>
        </main>
      </div>
    </Router> 
  );
}

export default App;